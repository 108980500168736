/* eslint-disable */
import Validator from 'validatorjs/src/validator'
import './imask'

(function () {

    var formPHONE = $('.form-control-phone');

    if(formPHONE.length) {

      formPHONE.each(function (i, el)  {
        var placeholderContainer = $(el).find('.placeholders__container'),
          placeholderStatic = placeholderContainer.find('.placeholder-static'),
          placeholderDynamic = placeholderContainer.find('.placeholder-dynamic'),
          placeholderDynamicMask = '+___________';

        var caseMask = IMask(el, {
          mask: '{+}`NM',
          lazy: true,
          blocks: {
            NM: {
              mask: '000000000000000'
            }
          }
        });
        // placeholderDynamic.text(placeholderDynamicMask)
        formPHONE.on('focus', function () {
          $(this).parent('.form-group-phone').addClass('focused')
        });
        formPHONE.on('blur', function () {
          $(this).parent('.form-group-phone').removeClass('focused')
        });

        caseMask.on('accept', function () {
          if(caseMask.value.length >= 5) {
            formPHONE.parent('.form-group-phone').addClass('changed')
          } else {
            formPHONE.parent('.form-group-phone').removeClass('changed')
          }
          dynamicMask(caseMask.value, placeholderDynamic);
        })
        function dynamicMask (value, container) {
          var placeholderTransparent = container.find('.placeholder-transparent'),
            placeholderHint = container.find('.placeholder-hint');

            placeholderTransparent.text(value);

            placeholderHint.text(placeholderDynamicMask.slice(value.length, placeholderDynamicMask.length));
        }

        formPHONE.on('paste', function(e){
          e.preventDefault();
          var clipboardCurrentData = (e.originalEvent || e).clipboardData.getData('text/plain').replace(/[^0-9]/g, "").split('');

          if(clipboardCurrentData.length >= 11)
            clipboardCurrentData = clipboardCurrentData.slice(1, clipboardCurrentData.length)

          window.document.execCommand('insertText', false, clipboardCurrentData.join(''));
        });

      })
    }
    var formSearchId = $('._js_input_search_by_id')
    if(formSearchId.length) {
      IMask(formSearchId[0], {
        mask: '[********]'
      });
	  }

    if($('.form-control-container').length) {
      $('.form-control-container').each(function (i, el) {
        const placeholder = el.querySelector('.form-control-placeholder')
        let width = el.offsetWidth - placeholder.offsetWidth - 26;
        let text = el.querySelector('.form-control-js-text')
        el.querySelector('.form-control').addEventListener('input', (e) => {
          text.innerText = e.target.value;
          if(text.offsetWidth >= width) {
            placeholder.classList.add('hidden')
          } else {
            placeholder.classList.remove('hidden')
          }
          if(e.target.value.length) {
            placeholder.classList.add('moved')
          } else {
            placeholder.classList.remove('moved')
          }
        })
      })
    }

    var formCustomValidation = $('.mainform');

    if(!formCustomValidation.length) return;
    formCustomValidation.each(function (i, el) {
      var thisForm = $(el)
      let arrayValidsCheckBox = []

      if(thisForm.find('.checkbox-custom-group').length) {
        function arrayboolean(arr) {
          for(let item of arr) {
            if(!item) {
              return false;
            }
          }
          return true
        }

        thisForm.find('.checkbox-custom input:checkbox').each(function() {
          arrayValidsCheckBox.push($(this).prop('checked'));
        })
        thisForm.find('.checkbox-custom-control input').on('click', function () {
          thisForm.find('.checkbox-custom input:checkbox').not(this).prop('checked', this.checked);

          arrayValidsCheckBox.length = 0;
          thisForm.find('.checkbox-custom input:checkbox').each(function() {
            arrayValidsCheckBox.push($(this).prop('checked'));
          })
          checkValidationAll(thisForm);
        });
        thisForm.find('.checkbox-custom input:checkbox').change(function() {
          arrayValidsCheckBox.length = 0;
          thisForm.find('.checkbox-custom input:checkbox').each(function() {
            arrayValidsCheckBox.push($(this).prop('checked'));
          })
          if(arrayboolean(arrayValidsCheckBox)) {
            thisForm.find('.checkbox-custom-control input').prop('checked', this.checked)
          } else {
            thisForm.find('.checkbox-custom-control input').prop('checked', false)
          }
          checkValidationAll(thisForm);
          if(!arrayValidsCheckBox.includes(true)) {
            thisForm.find('.form-group-custom').addClass('has-error')
          } else {
            thisForm.find('.form-group-custom').removeClass('has-error')
          }
        });
      }

      var formControl = formCustomValidation.find('.form-control, .checkbox input'),
      defaultConfig = {
          errorClass: 'has-error',
          formControlContainer: '.form-group',
          formErrorTmpClass: 'form-field-error-label'
      },
      errorTmp = '<div class="'+defaultConfig.formErrorTmpClass+'">:text</div>';

      formControl.each(function () {
          var formName = $(this).attr('name'),
              _self = $(this).get(0);

          var findValidationFormItem = formCustomValidation.find('input[name="validation\['+formName+'\]"]');

          if(!findValidationFormItem.length) return;

          var validationMessages = findValidationFormItem.data('messages'),
              validationRules = findValidationFormItem.data('rule'),
              data = {}, rule = {}, messages = {},
              isCheckbox = $(this).is('[type=checkbox]'),
              checkboxIsChecked = isCheckbox ? $(this).is(':checked') ? true : false : '';

          validationMessages.map(function (item) {
              messages[item.rule] = item.message
          });

          rule[formName] = validationRules.indexOf('regex') === -1 ? validationRules : validationRules.split('|');
          data[formName] = !isCheckbox ? $(this).val() : checkboxIsChecked;

          _self.validator = new Validator(data, rule, messages);
          $(this).on('input', function () {
              var _this = $(this);
              checkboxIsChecked = isCheckbox ? _this.is(':checked') ? true : false : '';
              data[_this.attr('name')] = _this.val();
              _self.validator = new Validator(data, rule, messages);
              _self.validator.input[formName] = !isCheckbox ? _this.val() : checkboxIsChecked;


              if(!_this.hasClass('dirty')) {
                  _this.one('blur', function () {
                      _this.addClass('dirty');
                      if(_self.validator.passes()) return;

                      showError(_this.closest(defaultConfig.formControlContainer), _self.validator.errors.first(formName))
                  });
              } else {
                  _self.validator.fails(function () {
                      showError(_this.closest(defaultConfig.formControlContainer), _self.validator.errors.first(formName))
                  });
              }
              _self.validator.passes(function() {
                  hideError(_this.closest(defaultConfig.formControlContainer));
              });
              checkValidationAll(thisForm);
          });
      });
      checkValidationAll(thisForm);



      function checkValidationAll (form) {
        var formControl = form.find('input').not('[type=hidden]'),
            arrayValids = [];

        formControl.each(function () {
            var _this = $(this),
                _self = $(this).get(0);
            if(!_self.validator) return;

            arrayValids.push(_self.validator.passes());
        });
        if(thisForm.find('.checkbox-custom-group').length) {
          if(arrayValidsCheckBox.includes(true)) {
            form.find('[type=submit]').prop('disabled', arrayValids.includes(false))
          } else {
            form.find('[type=submit]').prop('disabled', true)
          }
        } else {
          form.find('[type=submit]').prop('disabled', arrayValids.includes(false))
        }

        if(arrayValids.includes(false)) {
            form.find('.btn-href-disabled').removeClass('disabled')
        } else {
            form.find('.btn-href-disabled').addClass('disabled')
        }
      }


      function showError (elem, text) {
        if(!elem.hasClass(defaultConfig.errorClass)) {
            elem.addClass(defaultConfig.errorClass)
            elem.removeClass('is-valid');
        }
        if(elem.find('.'+defaultConfig.formErrorTmpClass).length) {
            elem.find('.'+defaultConfig.formErrorTmpClass).replaceWith(errorTmp.replace(':text', text))
            elem.find('.'+defaultConfig.formErrorTmpClass).show()
        } else {
            elem.append(errorTmp.replace(':text', text))
        }
      }

      function hideError (elem) {
          elem.addClass('is-valid');
          if(elem.hasClass(defaultConfig.errorClass)) {
              elem.removeClass(defaultConfig.errorClass)
              if(elem.find('.'+defaultConfig.formErrorTmpClass).length) {
                  elem.find('.'+defaultConfig.formErrorTmpClass).hide();
              }
          }
      }

    })



    // function validateAll (form) {
    //     var formControl = form.find('input').not('[type=hidden]');

    //     formControl.each(function () {
    //         var _this = $(this),
    //             _self = $(this).get(0);

    //         _self.validator.fails(function() {
    //             showError(_this.closest(defaultConfig.formControlContainer), _self.validator.errors.first(_this.attr('name')))
    //         });
    //     });
    // }

})();

