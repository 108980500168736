/* eslint-disable */

import IMask from 'imask';
// import Choices from 'choices.js';

export default (() => {

  const phonesFields          = document.querySelectorAll('input[data-mask^="phone"]');
  const datesFields           = document.querySelectorAll('input[data-mask="date"]');
  const customFieldsMask      = document.querySelectorAll('input[data-mask^="custom"]');
  const fullnameFieldsMask    = document.querySelectorAll('input[data-mask^="fullname"]');
  const selects               = document.querySelectorAll('.ui-select');

  Array.from(customFieldsMask).forEach((field) => {
    let mask = field.getAttribute('data-mask').split('|')[1];
    mask = mask === "number"? Number : mask;
    if (!mask) {
      console.error(`Не указана маска у поля ${field.name}`)
      return
    }
    field.imask = IMask(field, {
      mask
    });
  })

  Array.from(datesFields).forEach((field) => {
    field.imask = IMask(field, {
      mask: Date
    });
  })

  Array.from(fullnameFieldsMask).forEach((field) => {
    field.imask = IMask(field, {
      mask: /^[a-zA-Z]+(([a-zA-Z][' -])?[a-zA-Z]*)*$/
    });
  })
  // Array.from(selects).forEach((item) => {
  //   item.choices = new Choices(item, {
  //     placeholder: true,
  //     searchEnabled: false,
  //     shouldSort: false,
  //     itemSelectText: '',
  //   });
  // });

})();
