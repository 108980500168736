export default (() => {

  const carouselContainer = document.querySelector('.promo-block-slider');

  // eslint-disable-next-line
  if(!carouselContainer) return;
  const slides = carouselContainer.querySelectorAll('.swiper-slide');
  const items = carouselContainer.querySelectorAll('.swiper-slide').length;
  const carousel = new Swiper(carouselContainer.querySelector('.swiper-container'), {
    // loop: items > 1,
    watchOverflow: items === 1,
    // autoplay: {
    //   delay: 5000,
    // },
    simulateTouch: (window.innerWidth <= 768),
    watchSlidesProgress: true,
    watchSlidesVisibility: true,
    observer: true,
    observeParents: true,
    navigation: {
      nextEl: carouselContainer.querySelector('.btn-carousel-next'),
      prevEl: carouselContainer.querySelector('.btn-carousel-prev'),
    },
    pagination: {
      el: carouselContainer.querySelector('.swiper-pagination'),
			clickable: true,
      renderBullet(index, className) {
        return `<div class="${className}">
                  <div class="title">${slides[index].querySelector('.pagination-title').innerHTML}</div>
                  <div class="desc">${slides[index].querySelector('.pagination-desc').innerHTML}</div>
                </div>`;
      },
    },
  });
  carousel.init();

})();
