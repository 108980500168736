/* eslint-disable */
import anime from 'animejs/lib/anime.es.js';

export default (() => {

	document.addEventListener("click", scrollTo, { passive: false });
	document.addEventListener("touchstart", scrollTo, { passive: false });

	function scrollTo (e) {
		let _self = e.target;

		if (_self.matches(".btn-scroll")) {
			let dataAnchor = _self.getAttribute('href');

			if(dataAnchor == undefined) return;

			let header = document.querySelector('.header');
			let elementToScroll = document.querySelector(dataAnchor);

			anime({
				targets: 'html, body',
				scrollTop:  elementToScroll.offsetTop - header.offsetHeight,
				easing: 'linear',
				duration: 800
			});

		}

	}


})();
