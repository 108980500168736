/* eslint-disable */
window.equalheight = () => {
  return {
    initequalheight(container, items, equalItems) {
      window.onload = () => {
        const wrap = document.querySelector(container)
        const itemTitle = wrap.querySelectorAll(equalItems);
        const itemsList = wrap.querySelectorAll(items);
        const arrayHeight = [];
        itemsList.forEach(element => {
          const equalItem = element.querySelector(equalItems);
          arrayHeight.push(equalItem.offsetHeight)
        })
        const equalMaxHeight = this.getMaxOfArray(arrayHeight);
        $(itemTitle).height(equalMaxHeight)
      };
    },
    getMaxOfArray(numArray) {
      return Math.max.apply(null, numArray);
    }
  }
}
