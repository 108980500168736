export default (() => {

  const carouselContainer = document.querySelector('.section-history-carousel');

  // eslint-disable-next-line
  if(!carouselContainer) return;

  // const items = carouselContainer.querySelectorAll('.swiper-slide').length;
  const carousel = new Swiper(carouselContainer.querySelector('.swiper-container'), {
    loop: false,
    watchOverflow: true,
    // autoplay: {
    //   delay: 5000,
    // },
    watchSlidesProgress: true,
    watchSlidesVisibility: true,
    observer: true,
    observeParents: true,
    navigation: {
      nextEl: carouselContainer.querySelector('.btn-carousel-next'),
      prevEl: carouselContainer.querySelector('.btn-carousel-prev'),
    },
    slidesPerView: 1,
    spaceBetween: 0,
    breakpoints: {
      640: {
        slidesPerView: 4,
        spaceBetween: 24,
      }
    }
  });
  carousel.init();

})();
