import 'bootstrap.native';
import Alpine from 'alpinejs'
import './lib/store'
import './lib/carousel'
import './lib/promo-carousel'
// import './lib/carousel-column'
import './lib/header'
import './lib/carousel-banner'
import './lib/map'
import './lib/collapse'
import './lib/scrolltop'
import './lib/forms'
import './lib/forms-new'
import { cookieBar } from './lib/cookie-bar'
import './lib/carousel-news'
import './lib/carousel-history'
import './lib/carousel-partners'
import './lib/promo-block-slider'
import './lib/equalHeight'
import './lib/modal'

Alpine.data(cookieBar.name, cookieBar.data)
window.Alpine = Alpine

Alpine.start()
