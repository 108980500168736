export default (() => {

  const header = document.querySelector('.header');

  function sidebarToggle (e) {
      if(e.target.classList.contains('btn--burger')) {
          e.preventDefault();
          header.classList.toggle("sidebar-opened")
      }
      if(e.target.classList.contains('close-sidebar')) {
          e.preventDefault();
          header.classList.toggle("sidebar-opened")
      }
      if(e.target.classList.contains('btn-icon-search')) {
        if(window.innerWidth >= 1024) {
          if(!header.querySelector('.sidebar-head').classList.contains("search-block-opened")) {
            e.preventDefault();
            header.querySelector('.sidebar-head').classList.toggle("search-block-opened")
          }
        }
      }
      if(e.target.classList.contains('btn-search-clear')) {
          if(window.innerWidth >= 1024) {
            e.preventDefault();
            header.querySelector('.sidebar-head').classList.toggle("search-block-opened")
            document.querySelector('.search-block input').value = '';
          } else {
            e.preventDefault();
            document.querySelector('.search-block input').value = '';
          }
      }
      if(e.target.classList.contains('item') && e.target.querySelector('.dropdown-toggle')) {
        e.preventDefault();
        e.target.closest('.dropdown').classList.toggle("is--open")
      }
  }
  document.addEventListener("click", sidebarToggle, { passive: false });
  document.addEventListener("touchstart", sidebarToggle, { passive: false });

  let scrollPosition = window.scrollY;

  window.addEventListener('scroll',() => {

      scrollPosition = window.scrollY;

      if (scrollPosition >= 30) {
          header.classList.remove('not-scrolled');
      } else {
          header.classList.add('not-scrolled');
      }

  });

})();
